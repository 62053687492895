let localConfig;
try {
    localConfig = require('./local_config').local;
} catch (e) {
    localConfig = {};
}

// Production values
const prodConfig = {
    'environment': 'production',
    'socketURL': 'https://rtm.invite.app',
    'apiURL': window.origin + '/api_v1/',
    'appId': '397500593755474',
    'fbApiVersion': 'v18.0',
    'paypal': {
        'clientId': 'Ac7jtMZuD75MgDXIE0O5m-g0odbEmm3CyofWJiKTfr5nZrfRG5YLZ7RyTuMhVEF2nZ5ucqPt4h9TWac_',
        'planId': 'P-3K384091TF834310GM5QUSZA'
    }
};

export const siteConfig = {...prodConfig, ...localConfig};

export const GOOGLE_API_KEY = 'AIzaSyA_eMInYp8jtgAQgxYwdfi0brWGTfkyntY';
